/******************************************************************************
 * vue関係なくapiアクセスしたい場合
 ******************************************************************************/
// api呼び出し用のメソッドをimport
import { login, logout, authorization } from "./api/auth";
import { downloadLogs } from "./api/logs";
import {
  getRebooter,
  postRebooterCreate,
  putRebooterUpdate,
  postRebooterControl,
} from "./api/rebooter";
import { getRebooters, postRebootersBulkOperation } from "./api/rebooters";
import {
  getUser,
  postUser,
  putUser,
  putUserPasswordExpiredAt,
  deleteUser,
} from "./api/user";
import { getUsers } from "./api/users";

// API経由で使用するメソッドを追記
export default {
  login,
  logout,
  authorization,
  downloadLogs,
  getRebooter,
  postRebooterCreate,
  putRebooterUpdate,
  postRebooterControl,
  getRebooters,
  postRebootersBulkOperation,
  getUser,
  deleteUser,
  postUser,
  putUser,
  putUserPasswordExpiredAt,
  getUsers,
};
